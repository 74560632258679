import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

const FooterSection = ({ title, path, sections }) => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()

  return (
    <li className='mr-12 mb-6'>
      {path !== '' ? (
        <Link to={`/${key}/${path}/`} className='mb-2 font-extrabold'>
          {title}
        </Link>
      ) : (
        <div className='mb-2 font-extrabold'>{title}</div>
      )}
      {sections.length > 0 && (
        <div className='mt-2 text-sm'>
          <div className='flex flex-col'>
            {sections.map((item, index) => (
              <ul key={index}>
                {item.links.map((item, index) => (
                  <li key={index} className='mb-2'>
                    {item.external ? (
                      <a
                        href={item.uri}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-blue hover:text-cyan'
                      >
                        {item.name}
                      </a>
                    ) : (
                      <Link
                        to={
                          item?.uriFull
                            ? item.uriFull
                            : `/${key}/${item.uri}${item.uri.includes('#') || item.uri.includes('?') ? '' : '/'}`
                        }
                        className='text-blue hover:text-cyan'
                      >
                        {item.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      )}
    </li>
  )
}

FooterSection.defaultProps = {
  path: '',
  sections: []
}

FooterSection.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.object)
}

export default FooterSection
