import React, { useState } from 'react'
import cx from 'classnames'
import { useIntl, FormattedMessage } from 'react-intl'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'

import { Button } from '~components/shared'
import { setUser } from '~utilities'

import Field from '~components/user/field'
import Error from '~components/user/error'

const Reset = ({ setPanel, setIsLoading }) => {
  const { messages } = useIntl()
  const [step, setStep] = useState(1)
  const [error, setError] = useState()
  const { register, errors, handleSubmit, getValues } = useForm()

  const forgotPassword = async username => {
    try {
      setIsLoading(true)
      await Auth.forgotPassword(username)
      setStep(2)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      setError(messages['error-reset-user-not-found'])
    }
  }

  const onSubmit = data => {
    const { email } = data
    const username = email
    forgotPassword(username)
  }

  const handleVerification = async () => {
    try {
      setIsLoading(true)
      const { email, code, password } = getValues()
      const username = email
      await Auth.forgotPasswordSubmit(username, code, password)
      await Auth.signIn(username, password)
      const user = await Auth.currentAuthenticatedUser()
      const userInfo = {
        ...user.attributes
      }
      setUser(userInfo)
      setIsLoading(false)
      setPanel('dashboard')
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={cx(step === 1 ? 'block' : 'hidden')}>
          <Field label={messages['email']}>
            <input type='email' name='email' ref={register({ required: true })} className='form-input' />
            {error && <Error>{error}</Error>}
          </Field>
          <div className='flex items-center mt-4 space-x-4'>
            <Button type='submit' iconClassName='fas fa-sign-in-alt'>
              <FormattedMessage id='reset-password' />
            </Button>
            <Button onClick={() => setPanel('sign-in')} iconClassName='fas fa-undo' transparent>
              <FormattedMessage id='back' />
            </Button>
          </div>
        </div>

        <div className={cx(step === 2 ? 'block' : 'hidden')}>
          <Field label={messages['new-password']} className='mt-4'>
            <input
              type='password'
              name='password'
              ref={register({
                minLength: 8
              })}
              className='form-input'
              onKeyPress={e => {
                e.key === 'Enter' && e.preventDefault()
              }}
            />
            {errors.password?.type === 'minLength' && (
              <Error>
                <FormattedMessage id='error-min-length' />
              </Error>
            )}
          </Field>
          <Field label={messages['verification-code']} className='mt-4'>
            <div className='flex items-center'>
              <div className='w-1/2'>
                <input type='text' name='code' ref={register} className='form-input' />
              </div>
              <div className='ml-4'>
                <Button onClick={handleVerification}>
                  <FormattedMessage id='verify-account' />
                </Button>
              </div>
            </div>
          </Field>
        </div>
      </form>
    </div>
  )
}

export default Reset
