import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useIntl, FormattedMessage } from 'react-intl'

import { localizeURL } from '~utilities'

import { langs } from '~data/languages'

const LanguageSwitcher = () => {
  const { locale } = useIntl()
  const { pathname: url } = useLocation()

  const LanguageLink = ({ lang }) => {
    return (
      <span className='flex h-full'>
        <Link
          to={localizeURL(lang, url)}
          className={cx(
            'flex items-center p-4 hover:bg-cyan-dark hover:text-black',
            locale === lang && 'bg-cyan-dark text-black'
          )}
        >
          <FormattedMessage id={lang} />
          <span className='sr-only'>
            <FormattedMessage id={`${lang}-Full`} />
          </span>
        </Link>
      </span>
    )
  }

  return langs.map(lang => <LanguageLink key={lang} lang={lang} />)
}

LanguageSwitcher.propTypes = {
  langs: PropTypes.array
}

export default LanguageSwitcher
