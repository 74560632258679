import React, { useState } from 'react'
import cx from 'classnames'
import { useIntl } from 'react-intl'

import { useEffectOnce } from '~hooks'
import { getUser } from '~utilities'

import Panels from '~components/user/panels'

const Account = () => {
  const { messages } = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const [panel, setPanel] = useState('sign-in')
  const user = getUser()

  let title = ''
  let description = ''
  switch (panel) {
    case 'dashboard':
      title = messages['dashboard-title']
      description = messages['dashboard-description']
      break
    case 'sign-in':
      title = messages['sign-in-title']
      description = messages['sign-in-description']
      break
    case 'reset':
      title = messages['reset-password-title']
      description = messages['reset-password-description']
      break
    case 'sign-up':
      title = messages['sign-up-title']
      description = messages['sign-up-description']
      break
    case 'verify':
      title = messages['verification-title']
      description = messages['verification-description']
      break
    case 'update':
      title = messages['dashboard-title']
      description = messages['dashboard-description']
      break
    default:
      break
  }

  useEffectOnce(() => {
    if (user) {
      setPanel('dashboard')
    }
  })

  return (
    <>
      <div className='md:w-2/5 bg-blue p-16 text-white'>
        <h2 className='font-bold text-3xl'>{title}</h2>
        <p>{description}</p>
      </div>
      <div className={cx(isLoading && 'opacity-25', 'md:w-3/5 bg-white p-8 h-full max-h-hero overflow-y-scroll')}>
        <Panels panel={panel} setPanel={setPanel} setIsLoading={setIsLoading} />
      </div>
    </>
  )
}

export default Account
