module.exports = {
  // Navigation
  'en-US': 'eng',
  'es-MX': 'esp',
  'en-US-Full': 'Inglés',
  'es-MX-Full': 'Español',

  // Age groups
  'expected': 'Esperando un bebé',
  'newborn': 'Recién nacido',
  'newborns': 'Recién nacidos',
  'newborns-with-age': 'Recién nacidos (0-3 meses)',
  'baby': 'Bebés',
  'babies': 'Bebés',
  'babies-with-age': 'Bebés (4-11 meses)',
  'toddler': 'Niño',
  'toddlers': 'Niños pequeños',
  'toddlers-with-age': 'Niños pequeños (1-2 meses)',
  'preschooler': 'Preescolar',
  'preschoolers': 'Niños en edad preescolar',
  'preschoolers-with-age': 'Niños en edad preescolar (3-5 años)',
  'not-child': 'Niños grandes',
  'months': 'Meses',
  'years': 'Años',

  // General titles and labels
  'ages': 'Edades',
  'all': 'Todo',
  'all-ages': 'Todas las edades',
  'contact-info': 'Información de contacto',
  'counties': 'Condados',
  'county-map': 'Mapa del condado',
  'email': 'Correo electrónico',
  'home-indicator': 'Estás en la página de inicio',
  'label-children': 'Fecha de vencimiento/cumpleaños del niño(a)',
  'label-interests': 'Interés',
  'language': 'Idioma',
  'menu': 'Menú',
  'month': 'Mes',
  'new-password': 'Contraseña nueva',
  'no-account': '¿No tienes una cuenta?',
  'no-children': 'Sin hijos',
  'no-interests': 'No interés',
  'old': '',
  'optional': 'opcional',
  'parent-guide': 'Guía para padres de CA',
  'password': 'Contraseña',
  'phone': 'Teléfono',
  're-enter-email': 'Ingresa de nuevo tu correo electrónico',
  'state-site': 'Visita la página Estatal para obtener más información de First 5 California',
  'stronger-starts': 'Estrés tóxico',
  'stronger-starts-footer': 'Cómo proteger a los niños del estrés tóxico',
  'summary': 'Resumen',
  'toxic-stress': 'Respuesta al estrés tóxico',
  'verification-code': 'Código de verificación',
  'website': 'Sitio web',
  'website-subtitle': 'Sitio web para padres de California',
  'year': 'Año',
  'your-children': 'Tus hijos',
  'your-interests': 'Tus intereses',
  'zip-codes': 'Códigos postales',

  // Main titles and labels
  'about': 'Acerca de',
  'activities-and-more': 'Actividades y más',
  'all-services': 'Todos los servicios',
  'articles': 'Artículos',
  'articles-category': 'Artículos por categoría',
  'browse-by-ages': 'Buscar por edades',
  'county-search-label': 'Escribe el nombre de tu condado o tu código postal',
  'dashboard': 'Mi cuenta',
  'explore-topics': 'Explorar temas',
  'featured': 'Destacados',
  'featured-this-week': 'Destacado esta semana',
  'featured-this-month': 'Destacado este mes',
  'filtered-by': 'Filtrado por',
  'find-help': 'Buscar ayuda',
  'hide-filters': 'Ocultar filtros',
  'hide-sources': 'Ocultar fuentes',
  'home': 'Inicio',
  'loading': 'Cargando...',
  'more-activities': 'Más actividades y juegos',
  'more-books': 'Más recursos para ti',
  'more-videos': 'Videos relacionados',
  'new-and-trending': 'Novedades y tendencias',
  'no-county-results': 'No hemos encontrado ese condado o código postal',
  'none': 'Ninguno',
  'our-mission': 'Nuestra misión',
  'our-purpose': 'Nuestro propósito',
  'partners': 'Socios',
  'recommended-articles': 'Artículos recomendados',
  'recommended-search-terms': 'Términos recomendados',
  'series-info': 'Información de series',
  'services': 'Servicios',
  'show-filters': 'Mostrar filtros',
  'show-sources': 'Mostrar fuentes',
  'special-topics': 'Temas especiales',
  'stay-in-touch': 'Síguenos',
  'table-of-contents': 'Avanzar',
  'trending-questions': 'Preguntas frecuentes',
  'videos': 'Videos',
  'videos-category': 'Videos por categoría',
  'top-picks-for-you': 'Las mejores opciones para ti',

  // Sidebar titles and labels
  'activities-label': 'Actividades',
  'activities-title': 'Las 3 mejores actividades para padres y madres',
  'reviews-label': 'Lee las reseñas',
  'reviews-title': 'Los padres y madres recomiendan',
  'services-label': 'Ayuda local',
  'video-label': 'Video destacado',

  // Footer
  'conditions-of-use': 'Condiciones de uso',
  'contact-us': 'Contacto',
  'disclaimer': 'Esta página de Internet brinda información general con fines educativos únicamente. Si tienes alguna pregunta o inquietud sobre tu salud o la salud de tus niños, debes siempre consultar con un médico u otro profesional de la salud. Lee las condiciones de uso antes de utilizar este sitio. Al hacer uso de esta página, quiere decir, que estas de acuerdo con las ',
  'privacy-policy': 'Política de privacidad',
  'site-map': 'Mapa del sitio',
  'talk-read-sing': 'Hablar. Leer. Cantar. Promueven la conciencia pública sobre los problemas que afectan el desarrollo de la infancia, el desarrollo del cerebro a temprana edad, y la crianza de los hijos.',

  // Calls to action
  'add-child': 'Agrega a tu hijo',
  'back': 'Vuelve a la página de inicio',
  'clear-filters': 'Borrar filtros',
  'close': 'Cerrar',
  'continue': 'Continúa',
  'continue-watching': 'Continuar viendo',
  'contributed-by': 'Aportación de',
  'download-now': 'Descargar ahora',
  'download-pdf': 'Descargar PDF',
  'get-verification-code': 'Obtén tu código de seguridad',
  'hide-all': 'Ocultar todo',
  'learn-more': 'Aprende más',
  'learn-more-state-site': 'Visita la página Estatal de First 5 California para obtener más información.',
  'log-in-sign-up': 'Inicia sesión / Regístrate',
  'log-out': 'Cierra sesión',
  'more': 'Más',
  'open': 'Abrir',
  'professional-resources': 'Encuentra recursos profesionales',
  'read-more': 'Más información',
  'read-now': 'Lee ahora',
  'read-online': 'Leer en línea',
  'remove': 'Elimina',
  'reset-password': 'Actualiza tu contraseña',
  'search': 'Búsqueda',
  'search-this-site': 'Buscar en este sitio web',
  'see-all': 'Ver todo',
  'see-all-questions': 'Ver todas las preguntas',
  'see-more-activities': 'Obtener actividades',
  'sign-in': 'Inicia sesión',
  'sign-up': 'Regístrate',
  'skip': 'Avanza',
  'start-over': 'Inicia nuevamente',
  'update': 'Actualizar',
  'verify-account': 'Verifica tu cuenta',
  'watch-now': 'Ver ahora',

  // Sharing and tags
  'share': 'Compartir',
  'share-page': 'Compartir esta publicación:',
  'share-prompt': '¿Ha sido útil?',
  'tag': 'Etiqueta',
  'tags': 'Etiquetas',

  // 404 page
  'page-not-found-title': 'Error 404: no se encuentra la página',
  'page-not-found-body': '¡Ups!',
  'page-not-found-cta': 'Parece que esta página no existe más.',
  'page-not-found-link': '¡Vuelve al inicio y comienza de nuevo!',

  // Search
  'age': 'Edad',
  'type': 'Tipo',
  'activity-type': 'Tipo de Actividad',
  'topic': 'Tema',
  'activity': 'Actividad',
  'article': 'Articulo',
  'book': 'Libro',
  'resource': 'Recurso',
  'service': 'Servicio',
  'video': 'Video',
  'notanactivity': 'No es una actividad',
  'activitiesandgames': 'Actividades y juegos',
  'childrensbooks': 'Libros para Niños',
  'coloringsheets': 'Hojas para colorear',
  'talking': 'Hablar',
  'reading': 'Leer',
  'search-all-pages': 'Buscar todas las páginas aquí...',
  'search-activities': 'Buscar actividades aquí...',
  'search-videos': 'Buscar videos aquí...',
  'results-found': 'Resultados encontrados',

  // User accounts
  'sign-in-title': 'Únete a nuestra familia First 5, ¡es gratis!',
  'sign-in-description': 'Disfruta de contenido personalizado basado en la edad de tu hijo cada vez que visites nuestro sitio.',
  'reset-password-title': 'Restablece tu contraseña.',
  'reset-password-description': '¡Volvamos a intentarlo!',
  'sign-up-title': 'Regístrate en segundos.',
  'sign-up-description': 'Rápido, seguro y sencillo. Y ten la seguridad de que nunca venderemos ni compartiremos tu información.',
  'verification-title': '¡Ya casi terminas!',
  'verification-description': 'Te enviamos un código de verificación único por correo electrónico. Simplemente ingrésalo aquí para confirmar tu nueva cuenta.',
  'dashboard-title': 'Te has registrado. ¡Bienvenido!',
  'dashboard-description': 'Revisa y actualiza tus datos aquí. (¡Siempre puedes volver y editar más tarde también!)',
  'dashboard-welcome': 'Bienvenidos a la familia First 5. ¡Estamos alegres de tenerte aquí! Nuestra página está diseñada para ofrecerte información útil que les ayudará en tu jornada como padres, desde el embarazo hasta la edad preescolar, además encontrarás información de acuerdo con la(s) edad(s) de tus hijos. ¡Listo, puedes comenzar!',
  'start-exploring': 'Empieza a explorar',
  'welcome': 'Hola',
  'name': 'Nombre',
  'label-newsletter': 'Únete a nuestra lista de correos',
  'marketing-opt-in-disclaimer': '¡Sí! Me gustaría recibir actualizaciones por correo electrónico de First 5 California sobre el nuevo contenido del sitio web y la información que será útil para mi familia.',

  // Error messages and alerts
  'alert-code-sent': 'Código de verificación enviado.',
  'error-child': 'Selecciona el mes y el año. Si deseas eliminar una entrada, haz clic en el botón de “Elimina”.',
  'error-code-mismatch': 'Ingresaste el código de verificación incorrecto. Vuelve a intentarlo.',
  'error-email': 'Escribe tu dirección de correo electrónico.',
  'error-min-length': 'Tu contraseña debe tener por lo menos 8 caracteres.',
  'error-not-authorized': 'El nombre del usuario o contraseña son incorrectos. Por favor, inténtalo de nuevo.',
  'error-password': 'Escribe tu contraseña.',
  'error-reset-user-not-found': 'El sistema no reconoce el nombre del usuario. ¡Por favor, regístrate de nuevo! El proceso es rápido.',
  'error-user-not-found': 'Esta cuenta no existe. Debes hacer clic para suscribirte.',
  'error-username-exists': 'Esta cuenta ya existe. Por favor, retrocede para iniciar sesión o restablecer tu contraseña.',
  'error-verify': 'Debemos verificar tu cuenta primero.'
}
