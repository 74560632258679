import React, { useContext, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import cx from 'classnames'
import { useAtom } from 'jotai'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'

import { userMenuAtom } from '~atoms'
import { getUser } from '~utilities'

import Menu from '~components/menu'
import User from '~components/user'
import Navigation from '~components/nav/navigation'
import { ClientOnly } from '~components/shared'

const Header = props => {
  const { locale, messages } = useIntl()
  const key = locale.toLowerCase()
  const data = useStaticQuery(staticQuery)
  const user = getUser()

  const [isUserMenuOpen, setIsUserMenuOpen] = useAtom(userMenuAtom)

  const { containsSearch } = props
  const settings = data.settings.nodes.find(({ node_locale }) => node_locale === locale)

  const { recommendedSearchQueries, facebookLink, instagramLink, pinterestLink, twitterLink } = settings

  // Search
  const [searchKey, setSearchKey] = useState('')

  function submitSearch(event) {
    event.preventDefault()
    navigate(`/${key}/search/?q=${searchKey}`)
  }

  // `/en-US/` and `/es-MX/` are both 7 long
  const isHome = props.location.pathname.length < 8

  return (
    <>
      <header role='banner'>
        <div
          className={cx(
            user ? 'border-pink' : 'border-blue',
            'fixed lg:absolute z-40 flex justify-between w-full border-t-4'
          )}
        >
          <div className='flex justify-end max-w-page px-4 lg:px-8 font-nunito font-extrabold'>
            <Menu containsSearch={containsSearch} />

            <ClientOnly>
              <div className='hidden lg:block'>
                <button
                  onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                  className={cx(
                    user ? 'bg-pink' : 'bg-blue',
                    'relative z-50 inline-flex all-center px-4 pt-2 pb-3  rounded-b-lg text-lg text-white'
                  )}
                >
                  <em className='fas fa-user-circle mr-2' />{' '}
                  <span className='font-bold'>
                    {user ? (
                      <>
                        <FormattedMessage id='welcome' />
                        {user?.['custom:name'] && `, ${user['custom:name']}`}
                      </>
                    ) : (
                      <FormattedMessage id='log-in-sign-up' />
                    )}
                  </span>
                </button>
              </div>
            </ClientOnly>
          </div>
        </div>
        <div className='hidden lg:block lg:pt-3 lg:pb-2 lg:bg-blue-light'>
          <ul className='flex max-w-page mx-auto px-8'>
            <li>
              <a
                href={facebookLink}
                target='_blank'
                rel='noopener noreferrer'
                className='relative z-50 inline-flex all-center w-5 h-5 mr-2 bg-black rounded-full text-2xs text-blue-light'
                aria-label='Facebook'
              >
                <em className='fab fa-facebook-f' />
              </a>
            </li>
            <li>
              <a
                href={pinterestLink}
                target='_blank'
                rel='noopener noreferrer'
                className='relative z-50 inline-flex all-center w-5 h-5 mr-2 bg-black rounded-full text-2xs text-blue-light'
                aria-label='Pinterest'
              >
                <em className='fab fa-pinterest-p' />
              </a>
            </li>
            <li>
              <a
                href={twitterLink}
                target='_blank'
                rel='noopener noreferrer'
                className='relative z-50 inline-flex all-center w-5 h-5 mr-2 bg-black rounded-full text-2xs text-blue-light'
                aria-label='Twitter'
              >
                <em className='fab fa-twitter' />
              </a>
            </li>
            <li>
              <a
                href={instagramLink}
                target='_blank'
                rel='noopener noreferrer'
                className='relative z-50 inline-flex all-center w-5 h-5 mr-2 bg-black rounded-full text-2xs text-blue-light'
                aria-label='Instagram'
              >
                <em className='fab fa-instagram' />
              </a>
            </li>
          </ul>
        </div>
        <div className='flex justify-between items-start lg:items-center max-w-page h-24 lg:h-auto mx-auto px-4 lg:px-8 py-4 border-t-4 border-white lg:border-t-0'>
          <h1 className='w-auto lg:w-56'>
            <Link to={`/${key}/`} className='block'>
              <img src='/images/logo.png' alt='First 5 California' className='w-auto lg:w-full h-12 lg:h-auto' />
              <div className='mt-2 font-bold text-xs lg:text-sm'>
                <FormattedMessage id='website-subtitle' />
              </div>
            </Link>
          </h1>
          {!containsSearch && (
            <div className='hidden lg:flex lg:flex-col'>
              <form onSubmit={submitSearch} className='search'>
                <div>
                  <em className='fas fa-search mr-1' />
                </div>
                <input
                  placeholder={messages['search-this-site']}
                  aria-label='Search this site'
                  onChange={e => setSearchKey(e.target.value)}
                />
                <button type='submit' className='submit w-32 whitespace-no-wrap'>
                  <FormattedMessage id='search' /> <em className='fas fa-caret-right ml-2' />
                </button>
              </form>
              <ul className='flex mt-4 space-x-4 text-sm'>
                {recommendedSearchQueries.map((query, index) => (
                  <li key={index}>
                    <Link to={`/${key}/search/?q=${query}`} className='text-blue hover:text-cornflower-blue underline'>
                      {query}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className='hidden'>
          <p className='sr-only'>{locale}</p>
          <p className='sr-only'>{isHome && <FormattedMessage id='home-indicator' />}</p>
        </div>
        <Navigation langs={props.langs} section={props.section} />
      </header>
      {isUserMenuOpen && <User isUserMenuOpen={isUserMenuOpen} setIsUserMenuOpen={setIsUserMenuOpen} />}
    </>
  )
}

Header.propTypes = {
  containsSearch: PropTypes.bool,
  langs: PropTypes.any,
  location: PropTypes.object,
  section: PropTypes.any
}

export default Header

const staticQuery = graphql`
  query {
    settings: allContentfulSettings(filter: { title: { eq: "Global Settings" } }) {
      nodes {
        node_locale
        recommendedSearchQueries: headerSearchRecommendedSearchQueries
        facebookLink
        instagramLink
        pinterestLink
        twitterLink
      }
    }
  }
`
