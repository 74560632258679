import React, { useState } from 'react'
import cx from 'classnames'
import { useIntl, FormattedMessage } from 'react-intl'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'

import Field from '~components/user/field'
import Alert from '~components/user/alert'
import Error from '~components/user/error'
import { Button } from '~components/shared'

const Verify = ({ setPanel, setIsLoading }) => {
  const { messages } = useIntl()
  const [isSentMessage, setIsSentMessage] = useState(false)
  const [error, setError] = useState()
  const { register, getValues } = useForm()

  const handleResendSignUp = () => {
    const { email } = getValues()
    const username = email
    Auth.resendSignUp(username).then(() => setIsSentMessage(true))
  }

  const handleVerification = async () => {
    try {
      setIsLoading(true)
      const { email, code } = getValues()
      const username = email
      await Auth.confirmSignUp(username, code)
      setPanel('sign-in')
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      if (error.code === 'CodeMismatchException') {
        setError(messages['error-code-mismatch'])
      }
      // The 'NotAuthorizedException' error occurs on `confirmSignUp()` above
      // when the user's status is already confirmed (viz., "User cannot be
      // confirmed. Current status is CONFIRMED") so it is safe to send them
      // back to the sign in panel.
      else if (error.code === 'NotAuthorizedException') {
        setPanel('sign-in')
      }
      setIsLoading(false)
    }
  }

  return (
    <>
      {!isSentMessage && (
        <Error>
          <FormattedMessage id='error-verify' />
        </Error>
      )}
      <form className='mt-4'>
        <div>
          <Field label={messages['re-enter-email']} className={cx(isSentMessage && 'hidden')}>
            <div className={cx(isSentMessage ? 'hidden' : 'flex items-center')}>
              <div className='w-1/2'>
                <input type='email' name='email' ref={register({ required: true })} className='form-input ' />
              </div>
              <div className='ml-4'>
                <Button onClick={handleResendSignUp}>
                  <FormattedMessage id='get-verification-code' />
                </Button>
              </div>
            </div>
          </Field>
          {isSentMessage && (
            <Alert>
              <FormattedMessage id='alert-code-sent' />
            </Alert>
          )}
          {isSentMessage && (
            <>
              <Field label={messages['verification-code']} className='mt-4'>
                <div className='flex items-center'>
                  <div className='w-1/2'>
                    <input type='text' name='code' ref={register} className='form-input' />
                  </div>
                  <div className='ml-4'>
                    <Button onClick={handleVerification}>
                      <FormattedMessage id='verify-account' />
                    </Button>
                  </div>
                </div>
              </Field>
              {error && <Error>{error}</Error>}
            </>
          )}
          <Button onClick={() => setPanel('sign-in')} iconClassName='fas fa-undo' transparent>
            <FormattedMessage id='back' />
          </Button>
        </div>
      </form>
    </>
  )
}

export default Verify
