/* eslint-disable no-undef */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import { useMediaCardData } from '~hooks'

const SEO = ({ lang, title, description, image, keywords, meta, articleId }) => {
  const { locale } = useIntl()
  const data = useStaticQuery(staticQuery)
  const settings = data.settings.nodes.find(({ node_locale }) => node_locale === locale)

  const articles = useMediaCardData()
  let article = {}

  if (articleId) {
    article = articles.find(({ contentful_id }) => contentful_id === articleId)
  }

  let pageTitle = title || article?.title || 'No title found'
  let pageDescription = description || article?.caption || settings?.websiteDescription || 'No description found'
  let pageImage = image || article?.image || settings.mediaCardPlaceholderImage.file.url

  if (!pageTitle.includes('First 5 California')) {
    pageTitle = pageTitle + ' | ' + settings.websiteTitle
  }

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang
      }}
      title={pageTitle}
      meta={[
        {
          property: `og:title`,
          content: pageTitle
        },
        {
          name: `description`,
          content: pageDescription
        },
        {
          property: `og:description`,
          content: pageDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: `https:${pageImage}?fm=jpg&fl=progressive&w=1200&h=630&fit=fill&q=60`
        },
        {
          property: `og:image:url`,
          content: `https:${pageImage}?fm=jpg&fl=progressive&w=1200&h=630&fit=fill&q=60`
        },
        {
          property: `og:image:secure_url`,
          content: `https:${pageImage}?fm=jpg&fl=progressive&w=1200&h=630&fit=fill&q=60`
        },
        {
          property: `og:image:width`,
          content: `1200`
        },
        {
          property: `og:image:height`,
          content: `630`
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
    >
      <meta name='facebook-domain-verification' content='s7hygqnv91wl4qsufhui254ku3qc06' />
      <meta name='p:domain_verify' content='840f6edfa97ffd6c00917c1811d677fa' />
      <link
        href='https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap'
        rel='stylesheet'
      />
      <link
        href='https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap'
        rel='stylesheet'
      />
      <link href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css' rel='stylesheet' />
      <script src='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/js/fontawesome.min.js' />
      <script
        async
        defer
        src='https://platform-api.sharethis.com/js/sharethis.js#property=5ee9feebb900a200126bbaea&product=inline-share-buttons'
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en-US',
  keywords: [],
  meta: []
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.array,
  articleId: PropTypes.string
}

export default SEO

const staticQuery = graphql`
  {
    settings: allContentfulSettings(filter: { title: { eq: "Global Settings" } }) {
      nodes {
        node_locale
        websiteTitle
        websiteDescription
        mediaCardPlaceholderImage {
          file {
            url
          }
        }
      }
    }
  }
`
