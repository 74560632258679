import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl, FormattedMessage } from 'react-intl'

import { getURI } from '~utilities'

import FooterSection from '~components/nav/footer-section'
import ENGLISH_ABOUT from '~data/en-US/about'
import ENGLISH_BROWSE_BY_AGES from '~data/en-US/browse-by-ages'
import ENGLISH_ACTIVITIES from '~data/en-US/activities'
import SPANISH_ABOUT from '~data/es-MX/about'
import SPANISH_BROWSE_BY_AGES from '~data/es-MX/browse-by-ages'
import SPANISH_ACTIVITIES from '~data/es-MX/activities'

const FooterNavigation = () => {
  const { locale, messages } = useIntl()
  const key = locale.toLowerCase()

  let aboutNav = {}
  let browseByAgesNav = {}
  let activitiesNav = {}
  switch (locale) {
    case 'en-US':
      aboutNav = ENGLISH_ABOUT
      browseByAgesNav = ENGLISH_BROWSE_BY_AGES
      activitiesNav = ENGLISH_ACTIVITIES
      break
    case 'es-MX':
      aboutNav = SPANISH_ABOUT
      browseByAgesNav = SPANISH_BROWSE_BY_AGES
      activitiesNav = SPANISH_ACTIVITIES
      break
    default:
      aboutNav = ENGLISH_ABOUT
      browseByAgesNav = ENGLISH_BROWSE_BY_AGES
      activitiesNav = ENGLISH_ACTIVITIES
      break
  }

  const data = useStaticQuery(staticQuery)
  const ageRegex = /^(Newborn |Baby |Toddler |Preschooler )/

  function alphabetizeByName(a, b) {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  }

  const servicesPart1 = [
    {
      name: messages['all-services'],
      uri: 'services'
    }
  ]
  let servicesPart2 = []
  data.services.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      servicesPart2 = servicesPart2.concat([
        {
          name: edge.node.title,
          uriFull: getURI(edge.node, key)
        }
      ])
    })

  const services = servicesPart1.concat(servicesPart2)

  const servicesNav = [
    {
      section: 'Services',
      links: services
    }
  ]

  let specialTopics = []
  data.specialTopics.nodes
    .find(({ node_locale }) => node_locale === locale)
    .topics.forEach(edge => {
      specialTopics = specialTopics.concat([
        {
          name: edge.title,
          uriFull: getURI(edge, key)
        }
      ])
    })

  let newbornTopics = []
  data.newbornTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      newbornTopics = newbornTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  newbornTopics.sort(alphabetizeByName)

  let babyTopics = []
  data.babyTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      babyTopics = babyTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  babyTopics.sort(alphabetizeByName)

  let toddlerTopics = []
  data.toddlerTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      toddlerTopics = toddlerTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  toddlerTopics.sort(alphabetizeByName)

  let preschoolerTopics = []
  data.preschoolerTopics.edges
    .filter(edge => edge.node.node_locale === locale)
    .forEach(edge => {
      preschoolerTopics = preschoolerTopics.concat([
        {
          name: edge.node.title.replace(ageRegex, ''),
          uriFull: getURI(edge.node, key)
        }
      ])
    })
  preschoolerTopics.sort(alphabetizeByName)

  const specialTopicsNav = [
    {
      section: messages['special-topics'],
      links: specialTopics
    }
  ]

  const newbornTopicsNav = [
    {
      section: messages['newborns'],
      links: newbornTopics
    }
  ]
  const babiesTopicsNav = [
    {
      section: messages['babies'],
      links: babyTopics
    }
  ]

  const toddlersTopicsNav = [
    {
      section: messages['toddlers'],
      links: toddlerTopics
    }
  ]

  const preschoolersTopicsNav = [
    {
      section: messages['preschoolers'],
      links: preschoolerTopics
    }
  ]
  return (
    <ul className='flex flex-col lg:flex-row'>
      <div className='lg:w-1/4'>
        <FooterSection title={messages['about']} path='about' sections={aboutNav} />
        <FooterSection title={messages['find-help']} path='services' sections={servicesNav} />
      </div>
      <div className='lg:w-1/4'>
        <FooterSection title={messages['browse-by-ages']} path='' sections={browseByAgesNav} />
        <FooterSection title={messages['newborns-with-age']} path='' sections={newbornTopicsNav} />
        <FooterSection title={messages['babies-with-age']} path='' sections={babiesTopicsNav} />
      </div>
      <div className='lg:w-1/4'>
        <FooterSection title={messages['toddlers-with-age']} path='' sections={toddlersTopicsNav} />
        <FooterSection title={messages['preschoolers-with-age']} path='' sections={preschoolersTopicsNav} />
      </div>
      <div className='lg:w-1/4'>
        <FooterSection title={messages['special-topics']} path='' sections={specialTopicsNav} />
        <FooterSection title={messages['videos']} path='videos' />
        <FooterSection title={messages['activities-and-more']} path='activities' sections={activitiesNav} />
      </div>
    </ul>
  )
}

export default FooterNavigation

const staticQuery = graphql`
  {
    services: allContentfulServiceDetailPage(sort: { fields: name }) {
      edges {
        node {
          title: name
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    specialTopics: allContentfulSettings(filter: { title: { eq: "Global Settings" } }) {
      nodes {
        node_locale
        topics: specialTopics {
          __typename
          ... on ContentfulTopicPage {
            title
            internal {
              type
            }
            node_locale
            slug
            type
          }
        }
      }
    }
    newbornTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "newborn" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    babyTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "baby" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    toddlerTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "toddler" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    preschoolerTopics: allContentfulTopicPage(
      filter: { primaryTopics: { elemMatch: { slug: { eq: "preschooler" } } } }
      sort: { fields: title }
    ) {
      edges {
        node {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
  }
`
