export const getMonths = key => {
  const dayjs = require(`dayjs`)

  let locale = ''
  switch (key) {
    case 'en-us':
      locale = 'en'
      break
    case 'es-mx':
      locale = 'es'
      require('dayjs/locale/es')
      break
    default:
      locale = 'en'
      break
  }

  let months = []
  let dateStart = dayjs('1970-01-01').locale(locale)
  for (let i = 0; i < 12; i++) {
    months.push({ MM: dateStart.format('MM'), MMMM: dateStart.format('MMMM') })
    dateStart = dateStart.add(1, 'month')
  }
  return months
}
