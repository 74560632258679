import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'react-intl'

// This custom React hook fetches all article data, transforms it according to
// media card component requirements, stores that data in an array, and returns
// either an array of all article data for the current locale or an object for a
// single, specific article if a Contentful id is provided in the 'id'
// parameter.

// When using the hook, name the constant to which it is assigned according to
// which method is used:

// For all articles: `const articles = useMediaCardData()`

// For a single article: `const article = useMediaCardData(contentfulId)`
// where contentfulId is the node.contentful_id of the article (not regular id)

export const useMediaCardData = contentfulId => {
  const _ = require(`lodash`)
  const data = useStaticQuery(staticQuery)
  const { articles } = data
  const { locale } = useIntl()

  const transformedArticles = articles.edges
    .map(({ node }) => {
      const article = {}
      const ageGroups = ['newborn', 'baby', 'toddler', 'preschooler']

      article.id = node.id
      article.contentful_id = node.contentful_id
      article.title = node.title
      article.locale = node.node_locale
      article.type = node.type ? node.type : 'Unknown'
      article.directory = 'unknown'
      article.activityType = node.activityType ? node.activityType : 'Not an activity'
      article.slug = node.slug

      if (node.primaryTopics) {
        article.ages = node.primaryTopics.filter(topic => ageGroups.includes(topic.slug))
        article.topics = node.primaryTopics.filter(topic => !ageGroups.includes(topic.slug))
        article.primaryTopics = node.primaryTopics
        article.tags = node.primaryTopics
      }

      if (node.secondaryTopics) {
        article.secondaryTopics = node.secondaryTopics
        article.tags = article.tags.concat(node.secondaryTopics)
      }

      if (node.bookImage && node.bookImage.file && node.bookImage.file.url) {
        article.image = node.bookImage.file.url
      } else if (
        node.contents &&
        node.contents.json &&
        node.contents.json.content &&
        node.contents.json.content.filter(node => node.data.target) &&
        node.contents.json.content.filter(node => node.data.target).length > 0 &&
        _.get(node.contents.json.content.filter(node => node.data.target)[0], 'data.target.fields.file.url')
      ) {
        article.image = _.get(
          node.contents.json.content.filter(node => node.data.target)[0],
          'data.target.fields.file.url'
        )
      } else if (node.youtubeVideoId) {
        let videoId = ''
        const urlRegex = node.youtubeVideoId.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        if (urlRegex[2] !== undefined) {
          videoId = urlRegex[2].split(/[^0-9a-z_-]/i)
          videoId = videoId[0]
        } else {
          videoId = urlRegex
        }
        article.image = `//img.youtube.com/vi/${videoId}/hqdefault.jpg`
      }

      if (node.subline && node.subline.internal && node.subline.internal.content) {
        article.caption = node.subline.internal.content
      } else {
        article.caption = 'No caption available'
      }

      switch (node.type) {
        case 'Activity':
          article.directory = 'activities'
          break
        case 'Article':
          article.directory = 'articles'
          break
        case 'Book':
          article.directory = 'books'
          break
        case 'Video':
          article.directory = 'videos'
          break
        default:
          break
      }

      article.url = `/${article.locale.toLowerCase()}/${article.directory}/${article.slug}/`

      if (node.question) {
        article.question = node.question
      }

      article.hasQuestion = Boolean(node.question)
      article.featured = Boolean(node.featured)

      return article
    })
    .map(node => node)

  const filteredArticles = transformedArticles.filter(article => article.locale === locale)

  if (contentfulId) {
    return filteredArticles.find(article => article.contentful_id === contentfulId)
  } else {
    return [...filteredArticles]
  }
}

const staticQuery = graphql`
  {
    articles: allContentfulActivityArticleBookOrVideoDetailPage {
      edges {
        node {
          id
          contentful_id
          node_locale
          title
          type
          activityType
          slug
          featured
          question
          primaryTopics {
            slug
            name
          }
          secondaryTopics {
            name
          }
          subline {
            internal {
              content
            }
          }
          contents {
            json
          }
          bookImage: Book_Image {
            description
            file {
              fileName
              url
            }
          }
          bookDescription: Book_Description {
            json
          }
          youtubeVideoId
        }
      }
    }
  }
`
