import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

const MobileSection = ({
  title,
  path,
  sections,
  id,
  menuState,
  submenuState,
  toggleState,
  toggleSubmenu,
  setShowMenu
}) => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()

  return (
    <li className='flex flex-col'>
      {sections && (
        <a
          id={id}
          href={'#' + id}
          onClick={() => toggleState(id)}
          className={`flex justify-between p-4 ${
            menuState === id ? 'bg-cyan-dark' : ''
          } hover:bg-cyan-dark font-nunito font-extrabold text-base`}
          activeClassName='bg-cyan-dark'
        >
          <div>{title}</div> <em className={`fas ${menuState === id ? 'fa-minus' : 'fa-plus'}`} />
        </a>
      )}
      {!sections && (
        <Link
          to={`/${key}/${path}/`}
          onClick={() => setShowMenu(false)}
          className='flex p-4 hover:bg-cyan-dark font-nunito font-extrabold text-base'
          activeClassName='bg-cyan-dark'
        >
          <div>{title}</div>
        </Link>
      )}
      <div className={`${menuState === id ? 'flex' : 'hidden'} flex-col border-t border-b border-white`}>
        {sections.length > 1 &&
          sections.map((item, index) => (
            <React.Fragment key={index} id={item.section}>
              {item.links && (
                <button
                  onClick={() => toggleSubmenu(item.section)}
                  className={`flex justify-between p-4 ${
                    submenuState === item.section ? 'bg-cyan-dark' : ''
                  } hover:bg-cyan-dark font-nunito font-extrabold text-base`}
                >
                  <div>{item.section}</div>{' '}
                  <em className={`fas ${submenuState === item.section ? 'fa-minus' : 'fa-plus'}`} />
                </button>
              )}
              {!item.links && (
                <Link
                  to={`/${key}/${item.uri}`}
                  onClick={() => setShowMenu(false)}
                  className='p-4 hover:bg-cyan-dark font-nunito font-extrabold text-base'
                >
                  {item.section}
                </Link>
              )}
              {item.links &&
                submenuState === item.section &&
                item.links.map((item, index) => (
                  <Link
                    key={index}
                    to={
                      item?.uriFull
                        ? item.uriFull
                        : `/${key}/${item.uri}${item.uri.includes('#') || item.uri.includes('?') ? '' : '/'}`
                    }
                    onClick={() => setShowMenu(false)}
                    className='p-4 hover:bg-cyan-dark font-nunito font-extrabold text-base'
                  >
                    {item.name}
                  </Link>
                ))}
            </React.Fragment>
          ))}
        {sections.length === 1 &&
          sections.map((item, index) => (
            <React.Fragment key={index} id={item.section}>
              {!item.links && (
                <Link
                  to={`/${key}/${item.uri}`}
                  onClick={() => setShowMenu(false)}
                  className='p-4 hover:bg-cyan-dark font-nunito font-extrabold text-base'
                >
                  {item.section}
                </Link>
              )}
              {item.links &&
                item.links.map((item, index) => (
                  <Link
                    key={index}
                    to={
                      item?.uriFull
                        ? item.uriFull
                        : `/${key}/${item.uri}${item.uri.includes('#') || item.uri.includes('?') ? '' : '/'}`
                    }
                    onClick={() => setShowMenu(false)}
                    className='p-4 hover:bg-cyan-dark font-nunito font-extrabold text-base'
                  >
                    {item.name}
                  </Link>
                ))}
            </React.Fragment>
          ))}
      </div>
    </li>
  )
}

MobileSection.defaultProps = {
  path: ''
}

MobileSection.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  sections: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.bool]),
  id: PropTypes.number.isRequired
}

export default MobileSection
