import React, { useState, useEffect, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import cx from 'classnames'
import { useIntl, FormattedMessage } from 'react-intl'
import { Auth } from 'aws-amplify'
import { useForm, useFieldArray } from 'react-hook-form'

import { Button } from '~components/shared'
import { setUser, getMonths, getYears } from '~utilities'

import Field from '~components/user/field'
import Error from '~components/user/error'

const SignUp = ({ setPanel, setIsLoading }) => {
  const { locale, messages } = useIntl()
  const key = locale.toLowerCase()
  const data = useStaticQuery(staticQuery)
  const topics = data.topics.nodes.filter(({ node_locale }) => node_locale === locale)

  const [step, setStep] = useState(1)
  const [error, setError] = useState()

  const { control, register, errors, handleSubmit, getValues } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'child'
  })

  const handleSignUp = async (
    name,
    username,
    email,
    password,
    language,
    topicInterests,
    childrenDOBs,
    hasOptedIntoEmails
  ) => {
    try {
      setIsLoading(true)
      await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          'custom:name': name,
          'email': email,
          'custom:language': language,
          'custom:childrenDOBs': JSON.stringify(childrenDOBs),
          'custom:topicInterests': JSON.stringify(topicInterests),
          'custom:hasOptedIntoEmails': JSON.stringify(hasOptedIntoEmails)
        }
      })
      setError('') // remove any existing errors so it doesn't appear on the second step
      setStep(2)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      if (error.code === 'UsernameExistsException') {
        setError(messages['error-username-exists'])
      }
      setIsLoading(false)
    }
  }

  const handleVerification = async () => {
    try {
      setIsLoading(true)
      const { email, password, code } = getValues()
      const username = email
      await Auth.confirmSignUp(username, code)
      await Auth.signIn(username, password)
      const user = await Auth.currentAuthenticatedUser()
      const userInfo = {
        ...user.attributes
      }
      setUser(userInfo)
      setPanel('dashboard')
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      if (error.code === 'CodeMismatchException') {
        setError(messages['error-code-mismatch'])
      }
      // The 'NotAuthorizedException' error occurs on `confirmSignUp()` above
      // when the user's status is already confirmed (viz., "User cannot be
      // confirmed. Current status is CONFIRMED") so it is safe to proceed with
      // a sign in.
      else if (error.code === 'NotAuthorizedException') {
        setIsLoading(true)
        const { email, password } = getValues()
        const username = email
        await Auth.signIn(username, password)
        const user = await Auth.currentAuthenticatedUser()
        const userInfo = {
          ...user.attributes
        }
        setUser(userInfo)
        setPanel('dashboard')
        setIsLoading(false)
      }
      setIsLoading(false)
    }
  }

  const onSubmit = data => {
    const {
      name = '',
      password,
      email,
      topicInterests = [],
      language = 'english',
      child = [],
      hasOptedIntoEmails = true
    } = data
    const username = email
    let childrenDOBs = []
    child.forEach(({ month, year }) => childrenDOBs.push(`${year}-${month}`))
    handleSignUp(name, username, email, password, language, topicInterests, childrenDOBs, hasOptedIntoEmails)
  }

  const months = useMemo(() => getMonths(key), [key])
  const years = useMemo(() => getYears(), [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cx(step === 1 ? 'block' : 'hidden')}>
        <Field label={messages['name']}>
          <input
            type='text'
            name='name'
            ref={register({ required: true })}
            className='form-input'
            onKeyPress={e => {
              e.key === 'Enter' && e.preventDefault()
            }}
          />
        </Field>
        <Field label={messages['email']} className='mt-4'>
          <input
            type='email'
            name='email'
            ref={register({ required: true })}
            className='form-input'
            onKeyPress={e => {
              e.key === 'Enter' && e.preventDefault()
            }}
          />
          {error && (
            <div className='flex items-center space-x-4'>
              <Error>{error}</Error>
              <Button onClick={() => setPanel('sign-in')} iconClassName='fas fa-undo' transparent>
                <FormattedMessage id='back' />
              </Button>
            </div>
          )}
          {errors.email && (
            <Error>
              <FormattedMessage id='error-email' />
            </Error>
          )}
        </Field>

        <Field label={messages['password']} className='mt-4'>
          <input
            type='password'
            name='password'
            ref={register({
              required: true,
              minLength: 8
            })}
            className='form-input'
            onKeyPress={e => {
              e.key === 'Enter' && e.preventDefault()
            }}
          />
          {errors.password?.type === 'required' && (
            <Error>
              <FormattedMessage id='error-password' />
            </Error>
          )}
          {errors.password?.type === 'minLength' && (
            <Error>
              <FormattedMessage id='error-min-length' />
            </Error>
          )}
        </Field>

        <Field label={messages['language']} className='mt-4'>
          <div className='flex space-x-4'>
            <label key='language-english' className='flex items-center p-1 space-x-2'>
              <input
                type='radio'
                name='language'
                value='english'
                defaultChecked={true}
                ref={register}
                className='form-radio text-orange'
              />
              <span className='font-nunito font-bold text-sm'>English</span>
            </label>
            <label key='language-spanish' className='flex items-center p-1 space-x-2'>
              <input type='radio' name='language' value='spanish' ref={register} className='form-radio text-orange' />
              <span className='font-nunito font-bold text-sm'>Español</span>
            </label>
          </div>
        </Field>

        <Field label={messages['label-children']} className='mt-16' array optional>
          <ul className='space-y-4'>
            {fields.map((item, index) => (
              <li key={item.id} className='flex space-x-4'>
                <select
                  name={`child[${index}].month`}
                  defaultValue=''
                  ref={register({ required: true })}
                  className='form-select'
                >
                  <option value='' disabled>
                    {messages['month']}
                  </option>
                  {months.map(({ MM, MMMM }) => (
                    <option key={MM} value={MM}>
                      {MMMM}
                    </option>
                  ))}
                </select>

                <select
                  name={`child[${index}].year`}
                  defaultValue=''
                  ref={register({ required: true })}
                  className='form-select'
                >
                  <option value='' disabled>
                    {messages['year']}
                  </option>
                  {years.map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>

                <Button type='button' onClick={() => remove(index)} iconClassName='fas fa-times'>
                  <FormattedMessage id='remove' />
                </Button>
              </li>
            ))}
          </ul>
          {errors.child && (
            <Error>
              <FormattedMessage id='error-child' />
            </Error>
          )}
          <Button
            type='button'
            onClick={() => append({ month: '', year: '' })}
            className='mt-4'
            iconClassName='fas fa-plus'
          >
            <FormattedMessage id='add-child' />
          </Button>
        </Field>

        <Field label={messages['label-interests']} className='mt-16' optional>
          <div className='flex flex-wrap w-full -mx-2 pb-3'>
            {topics.map(({ name, slug }) => (
              <label key={slug} className='flex items-center w-1/3 p-1 space-x-2'>
                <input type='checkbox' name='topicInterests' value={slug} ref={register} className='form-checkbox' />
                <span className='font-nunito font-bold text-sm'>{name}</span>
              </label>
            ))}
          </div>
        </Field>

        <Field label={messages['label-newsletter']} className='mt-8'>
          <div className='flex space-x-4'>
            <label key='emails' className='flex items-center p-1 space-x-2'>
              <input
                type='checkbox'
                defaultChecked={true}
                name='hasOptedIntoEmails'
                ref={register}
                className='form-checkbox'
              />
              <span className='font-nunito font-bold text-sm'>
                <FormattedMessage id='marketing-opt-in-disclaimer' />
              </span>
            </label>
          </div>
        </Field>

        {error && <Error>{error}</Error>}

        <div className='flex mt-8 space-x-4'>
          <Button onClick={() => setPanel('sign-in')} iconClassName='fas fa-undo' transparent>
            <FormattedMessage id='back' />
          </Button>
          <Button type='submit'>
            <FormattedMessage id='continue' />
          </Button>
        </div>
      </div>

      <div className={cx(step === 2 ? 'block' : 'hidden')}>
        <div className='mb-8'>
          <h2 className='font-bold text-3xl'>
            <FormattedMessage id='verification-title' />
          </h2>
          <p>
            <FormattedMessage id='verification-description' />
          </p>
        </div>
        <Field label={messages['verification-code']}>
          <input type='text' name='code' ref={register} className='form-input' />
        </Field>
        {error && <Error>{error}</Error>}
        <div className='flex mt-4 space-x-4'>
          <Button onClick={() => setPanel('sign-in')} iconClassName='fas fa-undo' transparent>
            <FormattedMessage id='back' />
          </Button>
          <Button onClick={handleVerification}>
            <FormattedMessage id='verify-account' />
          </Button>
        </div>
      </div>
    </form>
  )
}

export default SignUp

const staticQuery = graphql`
  query {
    topics: allContentfulPrimaryTopic(sort: { fields: slug }) {
      nodes {
        node_locale
        name
        slug
      }
    }
  }
`
