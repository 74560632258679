import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

export const Tag = ({ name, query, children }) => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()
  return (
    <>
      <Link
        to={`/${key}/search/?${query}`}
        className='inline-block m-1 px-2 py-1 bg-gray-light font-medium text-2xs text-blue lowercase'
      >
        {children || name}
      </Link>
    </>
  )
}

Tag.defaultProps = {
  name: 'tag',
  query: undefined
}

Tag.propTypes = {
  name: PropTypes.string,
  query: PropTypes.string
}
