export const setUser = user => {
  if (typeof window !== 'undefined') {
    window.localStorage.user = JSON.stringify(user)
  }
}

export const getUser = () => {
  if (typeof window !== 'undefined' && window.localStorage.user) {
    const user = JSON.parse(window.localStorage.user)
    return user ? user : undefined
  }
  return undefined
}
