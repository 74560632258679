import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'

import { Button } from '~components/shared'
import { setUser } from '~utilities'

import Field from '~components/user/field'
import Error from '~components/user/error'

const LogIn = ({ setPanel, setIsLoading }) => {
  const { messages } = useIntl()
  const [error, setError] = useState()
  const { register, handleSubmit } = useForm()

  const signIn = async (username, password) => {
    try {
      setIsLoading(true)
      await Auth.signIn(username, password)
      const user = await Auth.currentAuthenticatedUser()
      const userInfo = {
        ...user.attributes
      }
      setUser(userInfo)
      setPanel('dashboard')
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      if (error.code === 'UserNotConfirmedException') {
        setPanel('verify')
      } else if (error.code === 'UserNotFoundException') {
        setError(messages['error-user-not-found'])
      } else if (error.code === 'NotAuthorizedException') {
        setError(messages['error-not-authorized'])
      }
      setIsLoading(false)
    }
  }

  const onSubmit = data => {
    const { password, email } = data
    const username = email
    signIn(username, password)
  }

  const handleReset = async () => {
    try {
      await Auth.signOut()
      setUser(null)
      setPanel('reset')
    } catch (error) {
      setUser(null)
      setPanel('reset')
      console.error(error)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field label={messages['email']}>
          <input type='email' name='email' ref={register({ required: true })} className='form-input' />
        </Field>
        <Field label={messages['password']} className='mt-4'>
          <input type='password' name='password' ref={register({ required: true })} className='form-input' />
        </Field>
        {error && <Error>{error}</Error>}
        <div className='flex items-center mt-4 space-x-4'>
          <Button type='submit' iconClassName='fas fa-sign-in-alt'>
            <FormattedMessage id='sign-in' />
          </Button>
          <Button onClick={handleReset} iconClassName='fas fa-unlock-alt' transparent>
            <FormattedMessage id='reset-password' />
          </Button>
        </div>
      </form>

      <div className='mt-16'>
        <Field label={messages['no-account']}>
          <Button onClick={() => setPanel('sign-up')} className='mt-2' iconClassName='fas fa-user-plus'>
            <FormattedMessage id='sign-up' />
          </Button>
        </Field>
      </div>
    </div>
  )
}

export default LogIn
