import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

export const NavCard = ({ title, caption, uri, color, hasTopShadow, extraClasses }) => {
  let bgColor = 'bg-black'
  let borderColor = 'border-black'
  let textHoverColor = 'group-hover:text-black'

  switch (color) {
    case 'blue':
      bgColor = 'bg-blue'
      borderColor = 'border-blue'
      textHoverColor = 'group-hover:text-blue'
      break
    case 'green':
      bgColor = 'bg-green'
      borderColor = 'border-green'
      textHoverColor = 'group-hover:text-green'
      break
    case 'orange':
      bgColor = 'bg-orange'
      borderColor = 'border-orange'
      textHoverColor = 'group-hover:text-orange'
      break
    case 'purple':
      bgColor = 'bg-purple'
      borderColor = 'border-purple'
      textHoverColor = 'group-hover:text-purple'
      break
    case 'red':
      bgColor = 'bg-red'
      borderColor = 'border-red'
      textHoverColor = 'group-hover:text-red'
      break
    default:
      break
  }

  const navCardClasses = cx(
    borderColor,
    hasTopShadow ? 'shadow-center-xl' : 'shadow-xl',
    'flex flex-col h-full w-full border-t-8',
    'bg-white p-4 overflow-hidden',
    'group',
    extraClasses
  )

  const content = (
    <>
      <h5 className='mb-4 font-bold text-2xl leading-tight'>{title}</h5>
      <p className='flex-grow text-sm leading-normal'>{caption}</p>
      <span
        className={cx(
          bgColor,
          borderColor,
          'relative inline-flex justify-center items-center self-end w-12 h-12 -mr-6 -mb-6 border group-hover:bg-white rounded-full text-2xl text-white'
        )}
      >
        <em className={cx(textHoverColor, 'fas fa-caret-right')} />
      </span>
    </>
  )

  if (uri && uri !== '') {
    return (
      <Link to={uri} className={navCardClasses}>
        {content}
      </Link>
    )
  } else {
    return <div className={navCardClasses}>{content}</div>
  }
}
