import React from 'react'

const Error = ({ children }) => {
  return (
    <div className='inline-flex items-center my-4 space-x-2 p-2 bg-red rounded-lg font-extrabold text-sm text-white leading-tight'>
      <i className='fas fa-exclamation-triangle'></i> <span>{children}</span>
    </div>
  )
}

export default Error
