import React from 'react'

const Alert = ({ children }) => {
  return (
    <div
      className='inline-flex items-center my-4 space-x-2 p-2 bg-blue
     rounded-lg font-extrabold text-sm text-white leading-tight'
    >
      <i className='fas fa-info-circle'></i> <span>{children}</span>
    </div>
  )
}

export default Alert
