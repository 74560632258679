import React from 'react'
import { useIntl } from 'react-intl'

const Field = ({ label, array, optional, className, children }) => {
  const { messages } = useIntl()
  const contents = (
    <>
      <div className={className}>
        <span className='font-bold text-sm tracking-wider uppercase'>{label}</span>
        {optional && (
          <span className='inline-block ml-2 font-light text-xs'>
            {'('}
            {messages['optional']}
            {')'}
          </span>
        )}
      </div>
      {children}
    </>
  )

  if (array) return <div className='mb-8'>{contents}</div>
  else return <label className='mb-8'>{contents}</label>
}

export default Field
