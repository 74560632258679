import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useIntl, FormattedMessage } from 'react-intl'

import FooterNavigation from '~components/nav/footer-navigation'

const Footer = () => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()

  const data = useStaticQuery(staticQuery)
  const { settings } = data

  const {
    californiaParentGuideLink,
    californiaStateSiteLink,
    emailAddress,
    phoneNumber,
    facebookLink,
    instagramLink,
    pinterestLink,
    twitterLink
  } = settings

  return (
    <footer id='footer' role='contentinfo' className='bg-gray-light'>
      <div className='max-w-page mx-auto p-8 2xl:px-0 font-nunito leading-normal'>
        <div className='flex flex-wrap md:justify-between md:flex-no-wrap mb-4'>
          <FooterNavigation />
          <div className='w-full md:w-1/2 lg:w-auto flex flex-col text-center mx-auto lg:mx-0'>
            <div className='mb-2 font-extrabold'>
              <FormattedMessage id='contact-us' />
            </div>
            {/* @TODO setup global data lookup in contenful and have it on all pages. See the blogpost named ... */}
            <div>
              <FormattedMessage id='phone' />: {phoneNumber}
            </div>
            <div>
              <FormattedMessage id='email' />:{' '}
              <a href={`mailto:${emailAddress}`} className='text-blue hover:text-cyan underline'>
                {emailAddress}
              </a>
            </div>
            <ul className='flex justify-center mx-auto my-4'>
              <li>
                <a
                  href={facebookLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='inline-flex all-center w-5 h-5 mr-2 bg-blue rounded-full text-2xs text-gray-light'
                  aria-label='Facebook'
                >
                  <em className='fab fa-facebook-f' />
                </a>
              </li>
              <li>
                <a
                  href={pinterestLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='inline-flex all-center w-5 h-5 mr-2 bg-blue rounded-full text-2xs text-gray-light'
                  aria-label='Pinterest'
                >
                  <em className='fab fa-pinterest-p' />
                </a>
              </li>
              <li>
                <a
                  href={twitterLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='inline-flex all-center w-5 h-5 mr-2 bg-blue rounded-full text-2xs text-gray-light'
                  aria-label='Twitter'
                >
                  <em className='fab fa-twitter' />
                </a>
              </li>
              <li>
                <a
                  href={instagramLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='inline-flex all-center w-5 h-5 mr-2 bg-blue rounded-full text-2xs text-gray-light'
                  aria-label='Instagram'
                >
                  <em className='fab fa-instagram' />
                </a>
              </li>
            </ul>
            <ul className='text-sm my-4'>
              <li className='mb-2'>
                <Link to={`/${key}/privacy-policy/`} className='text-blue hover:text-cyan'>
                  <FormattedMessage id='privacy-policy' />
                </Link>
              </li>
              <li className='mb-2'>
                <Link to={`/${key}/conditions-of-use/`} className='text-blue hover:text-cyan'>
                  <FormattedMessage id='conditions-of-use' />
                </Link>
              </li>
              <li className='mb-2'>
                <Link to={`/${key}/sitemap/`} className='text-blue hover:text-cyan'>
                  <FormattedMessage id='site-map' />
                </Link>
              </li>
            </ul>
            <div className='mb-8 font-extrabold'>&copy; {new Date().getFullYear()} First 5 California</div>
            <div>
              <div className='button button-blue w-full px-8'>
                <a
                  href='https://first5california.strongerstarts.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FormattedMessage id='stronger-starts-footer'/>
                </a>
              </div>
            </div>
            <div>
              <div className='button button-blue-clear w-full'>
                <a href={californiaStateSiteLink} target='_blank' rel='noopener noreferrer'>
                  <FormattedMessage id='state-site' />
                </a>
              </div>
            </div>
            <div>
              <div className='button button-blue w-full'>
                <a
                  href={`${californiaParentGuideLink}${locale === 'es-MX' ? 'es-mx' : ''}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <em className='fas fa-book-open block w-full text-3xl' />
                  <FormattedMessage id='parent-guide' />
                </a>
              </div>
            </div>
            <div>
              <div>
                <a
                  href={`https://registertovote.ca.gov/${locale === 'es-MX' ? 'es-mx' : ''}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='block'
                >
                  <img
                    src={
                      locale === 'es-MX'
                        ? 'https://elections.cdn.sos.ca.gov/outreach/reg-to-vote-spanish.png'
                        : 'https://elections.cdn.sos.ca.gov/images/register-to-vote.png'
                    }
                    alt={
                      locale === 'es-MX'
                        ? 'Secretario de Estado - Registrarse para votar'
                        : 'Secretary of State - Register to Vote'
                    }
                    className='w-full object-contain'
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='my-4 text-xs text-center leading-normal'>
          <FormattedMessage id='talk-read-sing' />
        </div>
        <div className='text-xs text-center leading-normal'>
          <FormattedMessage id='disclaimer' />
          <Link to={`/${key}/conditions-of-use/`} className='font-extrabold text-blue hover:text-cyan'>
            <FormattedMessage id='conditions-of-use' />
          </Link>
          .
        </div>
      </div>
    </footer>
  )
}

export default Footer

const staticQuery = graphql`
  {
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      californiaParentGuideLink
      californiaStateSiteLink
      emailAddress
      phoneNumber
      facebookLink
      instagramLink
      pinterestLink
      twitterLink
    }
  }
`
