import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'

const Section = ({ title, path, section, sections }) => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()

  let sectionWidth = title === 'Find Help' ? 18 : sections.length * 12

  return (
    <li className='h-full'>
      <div
        className={`relative h-full hover:bg-cyan-dark hover:text-black ${section === path &&
          'bg-cyan-dark text-black'} group`}
      >
        {path !== '' ? (
          <Link to={`/${key}/${path}/`} className='flex all-center h-full p-4 leading-snug'>
            {title}
          </Link>
        ) : (
          <div className='flex all-center h-full p-4 leading-snug'>{title}</div>
        )}
        {sections.length > 0 && (
          <div
            className={`absolute left-0 z-50 hidden group-hover:flex justify-center ${sections.length > 2 &&
              'shift-left'}`}
            style={{ width: sectionWidth + 'rem' }}
          >
            <div className='flex w-full border-8 border-t-0 border-cyan-dark bg-white'>
              {sections.map((item, index) => (
                <div key={index} className='w-full pb-2'>
                  <div className='p-4 bg-cyan-dark'>{item.section}</div>
                  <ul className='m-2 pt-2 border-b border-gray-light text-sm text-black leading-snug'>
                    {item.links.map((item, index) => (
                      <li key={index}>
                        {item.external ? (
                          <a
                            href={item.uri}
                            className='block p-2 border-t border-gray-light hover:bg-cyan-dark hover:text-black'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {item.name}
                          </a>
                        ) : (
                          <Link
                            to={
                              item?.uriFull
                                ? item.uriFull
                                : `/${key}/${item.uri}${item.uri.includes('#') || item.uri.includes('?') ? '' : '/'}`
                            }
                            className='block p-2 border-t border-gray-light hover:bg-cyan-dark hover:text-black'
                          >
                            {item.name}
                          </Link>
                        )}
                      </li>
                    ))}
                    <li>
                      <a
                        href='https://first5california.strongerstarts.com/'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='block p-2 border-t border-gray-light hover:bg-cyan-dark hover:text-black'
                      >
                        <FormattedMessage id='toxic-stress'/>
                      </a>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </li>
  )
}

Section.defaultProps = {
  path: '',
  sections: []
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  section: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object)
}

export default Section
