import React from 'react'

import Dashboard from '~components/user/dashboard'
import SignIn from '~components/user/sign-in'
import SignUp from '~components/user/sign-up'
import Verify from '~components/user/verify'
import Update from '~components/user/update'
import Reset from '~components/user/reset'

const Panels = ({ panel, setPanel, setIsLoading }) => {
  switch (panel) {
    case 'dashboard':
      return <Dashboard setPanel={setPanel} setIsLoading={setIsLoading} />
    case 'sign-in':
      return <SignIn setPanel={setPanel} setIsLoading={setIsLoading} />
    case 'sign-up':
      return <SignUp setPanel={setPanel} setIsLoading={setIsLoading} />
    case 'verify':
      return <Verify setPanel={setPanel} setIsLoading={setIsLoading} />
    case 'update':
      return <Update setPanel={setPanel} setIsLoading={setIsLoading} />
    case 'reset':
      return <Reset setPanel={setPanel} setIsLoading={setIsLoading} />
    default:
      return null
  }
}

export default Panels
