const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_QStWOvM1m',
  aws_user_pools_web_client_id: '3tutgmch38as4bjvvg31ee6737',
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: 'AdminQueries',
      endpoint: 'https://bs1opbeoqi.execute-api.us-west-2.amazonaws.com/dev',
      region: 'us-west-2'
    }
  ]
}

export default awsmobile
