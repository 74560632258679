export const getAge = ageInMonths => {
  if (ageInMonths < 0) return [`0`, `months`]
  else if (ageInMonths < 12) return [`${ageInMonths.toFixed(0).toString()}`, `months`]
  else return [`${(ageInMonths / 12).toFixed(0).toString()}`, `years`]
}

export const getAgeGroup = ageInMonths => {
  const ageInYears = (ageInMonths / 12).toFixed(0)
  if (ageInMonths < 0) return `expected`
  else if (ageInMonths <= 3) return `newborn`
  else if (ageInYears < 1) return `baby`
  else if (ageInYears < 3) return `toddler`
  else if (ageInYears < 6) return `preschooler`
  else return `not-child`
}

export const getAgeGroupSlug = ageInMonths => {
  const ageInYears = (ageInMonths / 12).toFixed(0)
  if (ageInMonths <= 3) return `newborn`
  else if (ageInYears < 1) return `baby`
  else if (ageInYears < 3) return `toddler`
  else if (ageInYears < 6) return `preschooler`
  return null
}
