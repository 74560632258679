import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useAtom } from 'jotai'
import { useIntl, FormattedMessage } from 'react-intl'
import dayjs from 'dayjs'
import { Auth } from 'aws-amplify'

import { userMenuAtom } from '~atoms'
import { Button } from '~components/shared'
import { setUser, getUser, getAge, getAgeGroup } from '~utilities'

import Field from '~components/user/field'

const Dashboard = ({ setPanel, setIsLoading }) => {
  const [, setIsUserMenuOpen] = useAtom(userMenuAtom)
  const { locale, messages } = useIntl()
  const data = useStaticQuery(staticQuery)
  const topics = data.topics.nodes.filter(({ node_locale }) => node_locale === locale)
  const user = getUser()
  const children = user ? JSON.parse(user['custom:childrenDOBs']) : null
  const interests = user ? JSON.parse(user['custom:topicInterests']) : null

  const signOut = async () => {
    try {
      setIsLoading(true)
      await Auth.signOut()
      setUser(null)
      setPanel('sign-in')
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setUser(null)
      setPanel('sign-in')
      setIsLoading(false)
    }
  }

  return (
    <div className='h-full'>
      <div className='absolute top-0 right-0 z-60 w-12 h-12 bg-white transform -translate-x-1/2' />
      <form className='relative'>
        <div className='flex w-full'>
          <div className='w-1/2'>
            <Field label={messages['your-children']}>
              <ul className='list mt-2 space-y-4'>
                {children && children.length === 0 && (
                  <li>
                    <FormattedMessage id='no-children' />
                  </li>
                )}
                {children &&
                  children.length > 0 &&
                  children.map((childDOB, index) => {
                    const ageInMonths = dayjs().diff(childDOB, 'months')
                    const ageGroup = getAgeGroup(ageInMonths)
                    const [ageNumber, unit] = getAge(ageInMonths)
                    return (
                      <li key={index} className='leading-none' style={{ marginBottom: '0' }}>
                        <div>
                          <div>{messages[ageGroup]}</div>
                          <div className='ml-0.5 mt-0.5 font-bold text-2xs tracking-widest uppercase'>
                            – {ageNumber} {messages[unit]} {messages['old']}
                          </div>
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </Field>
          </div>
          <div className='w-1/2'>
            <Field label={messages['your-interests']}>
              <ul className='list mt-2'>
                {interests && interests.length === 0 && (
                  <li>
                    <FormattedMessage id='no-interests' />
                  </li>
                )}
                {interests &&
                  interests.length > 0 &&
                  interests.map((interest, index) => (
                    <li key={index} className='leading-none capitalize' style={{ marginBottom: '0' }}>
                      {topics.find(topic => topic.slug === interest).name}
                    </li>
                  ))}
              </ul>
            </Field>
          </div>
        </div>
        <div className='mt-16'>
          <FormattedMessage id='dashboard-welcome' />
        </div>
        <div className='flex justify-between mt-8'>
          <Button onClick={() => setPanel('update')} iconClassName='fas fa-undo' transparent>
            <FormattedMessage id='start-over' />
          </Button>
          <Button onClick={() => setIsUserMenuOpen(false)} iconClassName='fas fa-arrow-right'>
            <FormattedMessage id='start-exploring' />
          </Button>
          <Button onClick={signOut} iconClassName='fas fa-sign-out-alt' transparent>
            <FormattedMessage id='log-out' />
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Dashboard

const staticQuery = graphql`
  query {
    topics: allContentfulPrimaryTopic(sort: { fields: slug }) {
      nodes {
        node_locale
        name
        slug
      }
    }
  }
`
