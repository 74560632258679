/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import Amplify from 'aws-amplify'
import { IntlProvider, FormattedMessage } from 'react-intl'
import useScript from 'react-script-hook'

import { useEffectOnce } from '~hooks'

import Header from '~components/header'
import Footer from '~components/footer'
import AWS_AMPLIFY_CONFIG from '~data/aws-amplify-config'
import ENGLISH_MESSAGES from '~data/en-US/messages'
import SPANISH_MESSAGES from '~data/es-MX/messages'

const Layout = ({ locale, location, section, containsSearch, children }) => {
  Amplify.configure(AWS_AMPLIFY_CONFIG)

  let messages = {}
  switch (locale) {
    case 'en-US':
      messages = ENGLISH_MESSAGES
      break
    case 'es-MX':
      messages = SPANISH_MESSAGES
      break
    default:
      messages = ENGLISH_MESSAGES
      break
  }

  useEffectOnce(() => {
    if (typeof window !== 'undefined') {
      let internal = window.location.host.replace('www.', '')
      internal = new RegExp(internal, 'i')
      let a = document.getElementsByTagName('a')
      for (var i = 0; i < a.length; i++) {
        let href = a[i].host
        if (!internal.test(href)) {
          a[i].setAttribute('target', '_blank')
        }
      }
    }
  })

  const [loading, error] = useScript({
    src: 'https://acsbapp.com/apps/app/dist/js/app.js',
    onload: () =>
      acsbJS.init({
        statementLink: '',
        footerHtml: '',
        hideMobile: false,
        hideTrigger: false,
        language: 'en',
        position: 'right',
        leadColor: '#146FF8',
        triggerColor: '#146FF8',
        triggerRadius: '50%',
        triggerPositionX: 'right',
        triggerPositionY: 'bottom',
        triggerIcon: 'people',
        triggerSize: 'medium',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: 'small',
          triggerPositionX: 'right',
          triggerPositionY: 'bottom',
          triggerOffsetX: 10,
          triggerOffsetY: 10,
          triggerRadius: '50%'
        }
      })
  })

  return (
    <IntlProvider defaultLocale='en-US' locale={locale} messages={messages}>
      <div className='no-js'>
        <div id='skip'>
          <a href='#maincontent' className='sr-only sr-only-focusable'>
            <FormattedMessage id='skip' />
          </a>
        </div>
        <Header location={location} section={section} containsSearch={containsSearch} />
        <main role='main' id='maincontent' name='maincontent'>
          {children}
        </main>
        <Footer />
      </div>
    </IntlProvider>
  )
}

Layout.defaultProps = {
  containsSearch: false
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  containsSearch: PropTypes.bool
}

export default Layout
