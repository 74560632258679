import React, { useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'
import { Auth } from 'aws-amplify'
import { useForm, useFieldArray } from 'react-hook-form'

import { getUser, setUser, getMonths, getYears } from '~utilities'

import Field from '~components/user/field'
import Error from '~components/user/error'
import { Button } from '~components/shared'

const Update = ({ setPanel, setIsLoading }) => {
  const { locale, messages } = useIntl()
  const key = locale.toLowerCase()
  const data = useStaticQuery(staticQuery)
  const topics = data.topics.nodes.filter(({ node_locale }) => node_locale === locale)

  const user = getUser()

  const { control, register, errors, handleSubmit } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'child'
  })

  const handleUpdate = async (name, language, topicInterests, childrenDOBs) => {
    try {
      setIsLoading(true)
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(currentUser, {
        'custom:name': name,
        'custom:language': language,
        'custom:topicInterests': JSON.stringify(topicInterests),
        'custom:childrenDOBs': JSON.stringify(childrenDOBs)
      })
      const updatedUser = await Auth.currentAuthenticatedUser()
      const userInfo = {
        ...updatedUser.attributes
      }
      setUser(userInfo)
      setPanel('dashboard')
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const onSubmit = data => {
    const { name = '', language = 'english', topicInterests = [], child = [] } = data
    let childrenDOBs = []
    child.forEach(({ month, year }) => childrenDOBs.push(`${year}-${month}`))
    handleUpdate(name, language, topicInterests, childrenDOBs)
  }

  const months = useMemo(() => getMonths(key), [key])
  const years = useMemo(() => getYears(), [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field label={messages['name']}>
        <input
          type='text'
          name='name'
          ref={register({ required: false })}
          className='form-input'
          defaultValue={user['custom:name'] || ''}
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
        />
      </Field>
      <Field label={messages['language']} className='mt-4'>
        <div className='flex space-x-4'>
          <label key='language-english' className='flex items-center p-1 space-x-2'>
            <input
              type='radio'
              name='language'
              value='english'
              defaultChecked={user['custom:language'] ? user['custom:language'] === 'english' : true}
              ref={register}
              className='form-radio text-orange'
            />
            <span className='font-nunito font-bold text-sm'>English</span>
          </label>
          <label key='language-spanish' className='flex items-center p-1 space-x-2'>
            <input
              type='radio'
              name='language'
              value='spanish'
              defaultChecked={user['custom:language'] ? user['custom:language'] === 'spanish' : false}
              ref={register}
              className='form-radio text-orange'
            />
            <span className='font-nunito font-bold text-sm'>Español</span>
          </label>
        </div>
      </Field>
      <Field label={messages['label-children']} array optional className='mt-8'>
        <ul className='space-y-4'>
          {fields.map((item, index) => (
            <li key={item.id} className='flex space-x-4'>
              <select
                name={`child[${index}].month`}
                defaultValue=''
                ref={register({ required: true })}
                className='form-select'
              >
                <option value='' disabled>
                  {messages['month']}
                </option>
                {months.map(({ MM, MMMM }) => (
                  <option key={MM} value={MM}>
                    {MMMM}
                  </option>
                ))}
              </select>
              <select
                name={`child[${index}].year`}
                defaultValue=''
                ref={register({ required: true })}
                className='form-select'
              >
                <option value='' disabled>
                  {messages['year']}
                </option>
                {years.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <Button type='button' onClick={() => remove(index)} iconClassName='fas fa-times'>
                <FormattedMessage id='remove' />
              </Button>
            </li>
          ))}
        </ul>
        {errors.child && (
          <Error>
            <FormattedMessage id='error-child' />
          </Error>
        )}
        <Button
          type='button'
          onClick={() => append({ month: '', year: '' })}
          className='mt-4'
          iconClassName='fas fa-plus'
        >
          <FormattedMessage id='add-child' />
        </Button>
      </Field>
      <Field label={messages['label-interests']} className='mt-8' optional>
        <div className='flex flex-wrap w-full -mx-2 pb-3'>
          {topics.map(({ name, slug }) => (
            <label key={slug} className='flex items-center w-1/3 p-1 space-x-2'>
              <input type='checkbox' name='topicInterests' value={slug} ref={register} className='form-checkbox' />
              <span className='font-nunito font-bold text-sm'>{name}</span>
            </label>
          ))}
        </div>
      </Field>
      <div className='flex mt-4 space-x-4'>
        <Button onClick={() => setPanel('dashboard')} iconClassName='fas fa-undo' transparent>
          <FormattedMessage id='back' />
        </Button>
        <Button type='submit'>
          <FormattedMessage id='update' />
        </Button>
      </div>
    </form>
  )
}

Update.propTypes = {
  setIsLoading: PropTypes.func,
  setPanel: PropTypes.func
}

export default Update

const staticQuery = graphql`
  query {
    topics: allContentfulPrimaryTopic(sort: { fields: slug }) {
      nodes {
        node_locale
        name
        slug
      }
    }
  }
`
