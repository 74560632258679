import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Tag } from '~components/shared'
import { ageGroups } from '~data/age-groups'

export const Tags = ({ type, tags, hideTitle }) => {
  if (tags && tags.length > 0) {
    return (
      <div>
        {!hideTitle && (
          <span className='extended mr-2 text-gray-dark'>
            <FormattedMessage id='tags' />:
          </span>
        )}
        {tags.map(({ name, slug = false }) => {
          let query = ''
          if (ageGroups.includes(slug)) {
            query = type ? `ages=${name}&types=${type}` : `ages=${name}`
          } else if (slug) {
            query = type ? `topics=${name}&types=${type}` : `topics=${name}`
          } else {
            query = type ? `types=${type}&q=${name}` : `&q=${name}`
          }
          return <Tag key={name} name={name} query={query} />
        })}
      </div>
    )
  } else {
    return <div />
  }
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object)
}
