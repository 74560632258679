import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { isUndefined } from 'lodash'

export const Button = ({ type, onClick, iconClassName, primary, transparent, href, className, children }) => {
  if (type || onClick) {
    return (
      <div className={className}>
        <button
          onClick={onClick}
          type={type}
          className={cx(
            transparent && primary ? 'border-pink bg-transparent hover:bg-pink' : undefined,
            transparent && !primary ? 'border-transparent hover:border-pink bg-transparent' : undefined,
            !transparent ? 'border-pink bg-pink hover:bg-transparent' : undefined,
            'inline-flex items-center p-2 border rounded-lg font-extrabold text-sm leading-tight group'
          )}
        >
          <span
            className={cx(
              transparent && primary ? 'text-pink group-hover:text-white' : undefined,
              transparent && !primary ? 'text-pink' : undefined,
              !transparent ? 'text-white group-hover:text-pink' : undefined
            )}
          >
            {children}
          </span>{' '}
          <em
            className={cx(
              iconClassName ? iconClassName : 'fas fa-caret-right',
              transparent && primary ? 'text-pink group-hover:text-white' : undefined,
              transparent && !primary ? 'text-pink' : undefined,
              !transparent ? 'text-white group-hover:text-pink' : undefined,
              'ml-2'
            )}
          />
        </button>
      </div>
    )
  } else if (href) {
    return (
      <div className={className}>
        <a
          href={href}
          target='_blank'
          rel='noopener noreferrer'
          className='inline-flex justify-center items-center mb-4 p-4 border border-pink rounded-lg bg-pink hover:bg-transparent font-extrabold leading-tight group'
        >
          <span className='text-white group-hover:text-pink'>{children}</span>{' '}
          <em className='fas fa-caret-right ml-2 text-white group-hover:text-pink' />
        </a>
      </div>
    )
  } else {
    return null
  }
}

Button.defaultProps = {
  onClick: null,
  primary: false,
  type: 'button'
}

Button.propTypes = {
  iconClassName: PropTypes.string,
  primary: PropTypes.bool,
  transparent: PropTypes.bool,
  href: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired
}
