export const getYears = () => {
  const dayjs = require(`dayjs`)
  let years = []
  let dateStart = dayjs().subtract(5, 'y')
  for (let i = 0; i < 7; i++) {
    years.push(dateStart.format('YYYY'))
    dateStart = dateStart.add(1, 'year')
  }
  return years
}
