import React, { useState } from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import cx from 'classnames'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAtom } from 'jotai'
import { userMenuAtom } from '~atoms'

import { getUser } from '~utilities'

import LanguageSwitcher from '~components/nav/language-switcher'
import MobileNavigation from '~components/nav/mobile-navigation'
import { ClientOnly } from '~components/shared'

const Menu = ({ setIsLoginOpen, containsSearch }) => {
  const { locale, messages } = useIntl()
  const key = locale.toLowerCase()
  const user = getUser()

  const [isUserMenuOpen, setIsUserMenuOpen] = useAtom(userMenuAtom)

  const parentGuideLink = `//parentguide.first5california.com/${key}/`

  const [showMenu, setShowMenu] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [searchKey, setSearchKey] = useState('')

  const toggleshowMenu = () => {
    if (showMenu === false) {
      setShowMenu(true)
    } else {
      setShowMenu(false)
    }
  }

  const toggleShowSearch = () => {
    setShowSearch(!showSearch)
  }

  function submitSearch(event) {
    event.preventDefault()
    navigate(`/${key}/search/?q=${searchKey}`)
  }

  return (
    <div className='lg:hidden'>
      {showMenu && !containsSearch && (
        <button onClick={() => toggleShowSearch()}>
          <em className='relative z-50 inline-flex all-center px-4 pt-2 pb-3 fas fa-search text-black' />
        </button>
      )}
      <button
        onClick={() => toggleshowMenu()}
        className='relative z-50 inline-flex all-center px-4 pt-2 pb-3 bg-blue rounded-b-lg text-white cursor-pointer'
      >
        <em className={cx(showMenu ? 'fas fa-window-close' : 'fas fa-bars', 'w-3 inline-flex justify-center mr-2')} />{' '}
        <FormattedMessage id='menu' />
      </button>
      {showMenu && (
        <div
          id='menu'
          className='absolute left-0 top-0 z-40 w-full max-h-screen bg-white overflow-y-scroll overflow-x-hidden'
        >
          <div className='flex justify-between items-center p-4 bg-white'>
            <Link className='block pb-4'>
              <img src='/images/logo.png' alt='First 5 California' className='w-auto h-8' />
              <div className='mt-2 font-montserrat font-bold text-xs'>
                <FormattedMessage id='website-subtitle' />
              </div>
            </Link>
          </div>
          {!containsSearch && (
            <div className={cx(showSearch ? 'inline-flex' : 'hidden', 'w-full -mt-4 px-4 pb-4')}>
              <form onSubmit={submitSearch} className='search'>
                <div>
                  <em className='fas fa-search mr-1' />
                </div>
                <input
                  placeholder={messages['search']}
                  aria-label={messages['search-this-site']}
                  onChange={e => setSearchKey(e.target.value)}
                />
                <button type='submit' className='submit w-32 whitespace-no-wrap'>
                  Search <em className='fas fa-caret-right ml-2' />
                </button>
              </form>
            </div>
          )}

          <div className='flex justify-between items-center bg-blue text-white'>
            <button id='mobile' onClick={() => setIsUserMenuOpen(!isUserMenuOpen)} className='inline-flex items-center px-4 py-2 hover:text-cyan'>
              <em className='fas fa-user-circle mr-2' />{' '}
              <span className='font-bold'>
                <ClientOnly>
                  {user ? <FormattedMessage id='dashboard' /> : <FormattedMessage id='log-in-sign-up' />}
                </ClientOnly>
              </span>
            </button>
            <div className='flex items-center space-x-4'>
              <div>
                <FormattedMessage id='language' />
              </div>
              <LanguageSwitcher />
            </div>
            {/* <button onClick={() => console.log('test')} className='block px-4 py-2 text-xl' aria-label='accessibility'>
              <em className='fas fa-universal-access' />
            </button> */}
          </div>
          <ul className='flex flex-col py-4 bg-blue border-t border-b border-white text-white'>
            <MobileNavigation setShowMenu={setShowMenu} />
            <li>
              <a
                href={parentGuideLink}
                target='_blank'
                rel='noreferrer noopener'
                className='flex justify-between p-4 hover:bg-cyan-dark'
                activeClassName='bg-cyan-dark'
              >
                <FormattedMessage id='parent-guide' />
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default Menu
