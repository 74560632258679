import React, { useContext, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'

import { userMenuAtom } from '~atoms'

import Account from '~components/user/account'

const User = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useAtom(userMenuAtom)

  const node = useRef()

  const handleClickOutside = element => {
    if (node.current.contains(element.target)) {
      return
    }
    setIsUserMenuOpen(false)
  }

  useEffect(() => {
    if (isUserMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isUserMenuOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className='fixed inset-0 z-100 flex all-center w-full h-full'
      style={{ backgroundColor: 'hsl(0 0% 0% / 0.75' }}
    >
      <div ref={node} id='user' className='relative flex flex-col md:flex-row w-full max-w-6xl min-h-sm-hero overflow-hidden bg-white'>
        <Account />
        <button onClick={() => setIsUserMenuOpen(false)} className='absolute top-0 right-0 mr-4 p-4'>
          <i className='fas fa-window-close text-2xl text-blue' />
        </button>
      </div>
    </div>
  )
}

export default User
